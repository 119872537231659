import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { ResultList } from "@app/models/resultList";
import { Municipality, Province, Purpose } from "@app/models/deco";
import { Institution } from "@app/models/institution";
import { AutocompleteItem } from "design-angular-kit";
import { State } from "@app/models/folder";

const systemBaseUrl = environment.servicesSystemUrl;
const dataBaseUrl = environment.servicesDataUrl;

@Injectable({
    providedIn: "root",
})
export class DecoService {
    constructor(private http: HttpClient) {}

    getMunicipality(id: string | number): Observable<Municipality> {
        return this.http.get<Municipality>(`${systemBaseUrl}/comune/${id}`);
    }

    getAllMunicipalities(): Observable<ResultList<Municipality>> {
        return this.http.get<ResultList<Municipality>>(`${systemBaseUrl}/comuni`);
    }

    searchMunicipalities = (search?: string | null): Observable<Array<AutocompleteItem>> => {
        if (search) {
            const params = new HttpParams().set("nome", search);
            return this.http.get<ResultList<Municipality>>(`${systemBaseUrl}/comuni/bynome`, { params }).pipe(
                map((res) =>
                    res.risultati.map((x: Municipality) => ({
                        value: x.nome,
                        additionalData: x.uid,
                    }))
                )
            );
        } else return of([]);
    };

    getAllProvinces(): Observable<ResultList<Province>> {
        return this.http.get<ResultList<Province>>(`${systemBaseUrl}/province`);
    }

    getAllInstitutions(): Observable<ResultList<Institution>> {
        return this.http.get<ResultList<Institution>>(`${systemBaseUrl}/enti`);
    }

    getAllInstitutionTypes(): Observable<ResultList<Institution>> {
        return this.http.get<ResultList<Institution>>(`${systemBaseUrl}/tipoEnti`);
    }

    getAllPurposes(): Observable<ResultList<Purpose>> {
        return this.http.get<ResultList<Purpose>>(`${dataBaseUrl}/immobile/deco/destinazioniuso`);
    }

    getAllFolderStates(): Observable<ResultList<State>> {
        return this.http.get<ResultList<State>>(`${dataBaseUrl}/fascicolo/deco/stati`);
    }
}
